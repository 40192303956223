import Vue from 'vue';

function blur() {
  document.activeElement.blur();
}

Vue.directive('clear-focus', {
  inserted(el) {
    el.addEventListener('click', blur);
  },
  unbind(el) {
    el.removeEventListener('click', blur);
  }
});
