function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}

export default {
  namespaced: true,
  state: {
    token: getCookie('JWT'),
  },
  getters: {
    isLoggedIn: state => state.token !== '',
    userInfo: (state, getters) => {
      return getters.isLoggedIn && JSON.parse(atob(state.token.split('.')[1]));
    },
  },
  actions: {
    login({ commit }) {
      commit('SET_TOKEN', getCookie('JWT'));
    },
    logout({ commit }) {
      eraseCookie('JWT');
      window.$crisp.push(["do", "session:reset"])
      commit('SET_TOKEN', '');
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
  },
};
