<template>
  <header>
    <div class="header__title">
      <img src="@/assets/images/manutan_logo.svg" alt="Logo Manutan">
      <h1>Service appels d’offres <span>Automatisation des AO par Golem.ai</span></h1>
    </div>
    <div class="right">
      <div class="routes" v-if="isLoggedIn">
        <router-link :to="{ name: 'home' }">Liste de documents</router-link>
        <router-link :to="{ name: 'insights' }">Insights</router-link>
      </div>
      <a href="#" class="user-infos" v-if="isLoggedIn">
        {{ userInfo.firstName }} {{ userInfo.lastName }}
        <span>
          <img src="@/assets/images/icons/i-chevron-left-black.svg">
        </span>
        <div @click="logout">Se déconnecter</div>
      </a>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  methods: {
    logout() {
      document.activeElement.blur();
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'login' });
    },
  },
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
      'userInfo',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.right {
  display: flex;
  align-items: center;

  .routes {
    margin-right: 50px;

    a {
      padding: 5px 10px;
      color: rgba(#000, .75);
      text-decoration: none;
      outline: none;

      &:hover {
        color: #0B2956;
      }

      &:not(:first-child) {
        border-left: 1px solid #C1C1C1;
      }

      &.router-link-exact-active {
        color: #0B2956;
        font-weight: 600;
      }
    }
  }
}

.user-infos {
  position: relative;
  border: solid 1px #ccc;
  border-radius: 6px;
  text-decoration: none;
  color: #0B2956;
  padding: 10px;
  transition: background-color .1s ease-out;

  span {
    margin: 0 10px;
    img {
      transition: transform .3s;
      transform: rotate(-90deg);
    }
  }

  div {
    position: absolute;
    display: none;
    top: calc(100% - 5px);
    padding: 10px;
    color: #0B2956;
    font-family: 'Poppins';
    font-size: 14px;
    background-color: #fff;
    left: 0;
    right: 0;
    outline: none;
    border: solid 1px #ccc;
    border-radius: 0 0 6px 6px;
    left: -1px;
    box-sizing: border-box;
    width: calc(100% + 2px);

    &:hover {
      background-color: #efefef;
    }
  }

  &:hover {
    background-color: rgba(#0B2956, .2);
  }

  &:focus {
    outline: none;

    span img {
      transform: rotate(90deg);
    }
    div {
      display: block;
    }
  }
}
</style>
