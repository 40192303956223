<template>
  <body>
    <Header />
    <router-view />
    <notifications
      transition="fade"
      classes="vue-notification"
      position="bottom left" />
  </body>
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',
  components: { Header },
  watch: {
    $route(to) {
      document.title = 'Manutan AO - ' + to.meta.title || 'Manutan AO'
    }
  },
}
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/elements.css"></style>
<style src="@/assets/css/vue-notification.css"></style>
