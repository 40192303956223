import Vue from 'vue';

/** @param {Date} date */
const dateTransformer = date => [
  date.getDate().toString().padStart(2, '0'),
  (date.getMonth() + 1).toString().padStart(2, '0'),
  date.getFullYear().toString().slice(-2),
].join('/');

Vue.filter('toDate', dateTransformer);

/**
 * Transforms a duration in milliseconds into a human readable duration
 * @param {number} seconds
 * @param {'short'|'long'} size
 */
export function secondsToReadableDuration(seconds, size = 'long') {
  let minutes;
  let hours;
  let days;

  function format(values) {
    let output = '';
    return {
      short() {
        if (values.hasOwnProperty('days') && values.days !== undefined) {
          output += `${values.days}j `;
        }
        if (values.hasOwnProperty('hours') && values.hours !== undefined) {
          output += `${values.hours}h `;
        }
        if (values.hasOwnProperty('minutes') && values.minutes !== undefined && !values.days) {
          output += `${values.minutes}m `;
        }
        if (values.hasOwnProperty('seconds') && values.seconds !== undefined && !values.hours) {
          output += `${values.seconds}s`;
        }

        return output;
      },
      long() {
        if (values.hasOwnProperty('days') && values.days !== undefined) {
          output += `${values.days} jour${values.days > 1 ? 's' : ''} `;
        }
        if (values.hasOwnProperty('hours') && values.hours !== undefined) {
          output += `${values.hours} heure${values.hours > 1 ? 's' : ''} `;
        }
        if (values.hasOwnProperty('minutes') && values.minutes !== undefined && !values.days) {
          output += `${values.minutes} minute${values.minutes > 1 ? 's' : ''} `;
        }
        if (values.hasOwnProperty('seconds') && values.seconds !== undefined && !values.hours) {
          output += `${values.seconds} seconde${values.seconds > 1 ? 's' : ''}`;
        }

        return output;
      },
    };
  }

  minutes = Math.floor(seconds / 60);
  seconds %= 60;

  if (minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes %= 60;

    if (hours > 24) {
      days = Math.floor(hours / 24);
      hours %= 24;
    }
  }

  return format({
    seconds, minutes, hours, days,
  })[size]();
}
