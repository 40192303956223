import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      title: 'Dashboard'
    },
  },
  {
    path: '/insights',
    name: 'insights',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/Insights.vue'),
    meta: {
      title: 'Insights'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
    meta: {
      title: 'Login'
    },
    beforeEnter(to, from, next) {
      if (store.getters['auth/isLoggedIn']) {
        return next('home');
      }
      return next()
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Register.vue'),
    meta: {
      title: 'Register'
    },
    beforeEnter(to, from, next) {
      if (store.getters['auth/isLoggedIn']) {
        return next('home');
      }
      return next()
    }
  },
  {
    path: '/demand/:tender/:demand',
    name: 'demand',
    component: () => import(/* webpackChunkName: "demand" */ '@/views/Demand.vue'),
    meta: {
      title: 'Demand'
    },
  },
  {
    path: '/list/:tender',
    name: 'list',
    component: () => import(/* webpackChunkName: "tender" */ '@/views/List.vue'),
    meta: {
      title: 'List'
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

function isAuthenticated() {
  return document.cookie.match(/^(.*;)?\s*JWT\s*=\s*[^;]+(.*)?$/)
}

router.beforeEach((to, from, next) => {
  if (!isAuthenticated() && !['login', 'register'].includes(to.name)) {
    return next({ name: 'login' });
  }

  return next();
})

export default router
