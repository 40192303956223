"use strict";

import Vue from 'vue';
import axios from 'axios';
import router from '@/router';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    if (document.cookie.match(/^(.*;)?\s*JWT\s*=\s*[^;]+(.*)?$/)) {
      const token = (document.cookie.match(/^(?:.*;)?\s*JWT\s*=\s*([^;]+)(?:.*)?$/)||[null])[1];
      const expiracy = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      if (new Date() > expiracy) {
        router.push({ name: 'login' });
        throw new axios.Cancel('Token expired');
      }
      config.headers.Authorization = 'Bearer ' + token;
    }
    // Do something before request is sent
    return config;
},
function(error) {
  // Do something with request error
  return Promise.reject(error);
}
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    console.log(error);

    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default _axios;
